import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { BounceLoader } from "react-spinners";

const DeepLinkHandler = () => {
  const { restaurantId } = useParams();
  const [status, setStatus] = useState("Preparing your experience...");

  useEffect(() => {
    const attemptAppOpen = async () => {
      const appScheme = `com.lusuku://restaurant/${restaurantId}`;
      const playStoreUrl =
        "https://play.google.com/store/apps/details?id=com.lusuku";
      const appStoreUrl = "https://apps.apple.com/ug/app/lusuku/id1622613658";

      // Detect iOS devices
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const storeUrl = isIOS ? appStoreUrl : playStoreUrl;

      try {
        setStatus("Opening Lusuku app...");
        window.location.href = appScheme;

        setTimeout(() => {
          setStatus(`Opening ${isIOS ? "App Store" : "Play Store"}...`);
          window.location.href = storeUrl;
        }, 2000);
      } catch (error) {
        window.location.href = storeUrl;
      }
    };

    attemptAppOpen();
  }, [restaurantId]);

  return (
    <Stack
      spacing={3}
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh", textAlign: "center", padding: "20px" }}
    >
      <BounceLoader size={50} color={"#00d50ec3"} />
      <h2>{status}</h2>
    </Stack>
  );
};

export default DeepLinkHandler;
